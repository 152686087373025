import {
  memoizedLoginViewModel,
  createLoginViewModel,
  LoginViewModel,
} from '../loginViewModel/loginViewModel';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  BookingDetailsViewModel,
  createBookingDetailsViewModel,
  createDummyBookingDetailsViewModel,
  memoizedBookingDetailsViewModel,
} from '../bookingDetailsViewModel/bookingDetailsViewModel';
import {
  isDailyAgendaWeeklyPickerLayout,
  isWeeklyTimetableLayout,
} from '../../../../utils/layouts';
import { Optional } from '../../../../types/types';
import {
  CtaViewModel,
  createCtaViewModel,
  createDummyCtaViewModel,
  memoizedCtaViewModel,
} from '../ctaViewModel/ctaViewModel';
import {
  SummaryViewModel,
  createSummaryViewModel,
  memoizedSummaryViewModel,
} from '../summaryViewModel/summaryViewModel';

export type SidebarViewModel = {
  title: string;
  bookingDetailsViewModel?: BookingDetailsViewModel;
  summaryViewModel?: SummaryViewModel;
  ctaViewModel: CtaViewModel;
  loginViewModel?: LoginViewModel;
};

export const memoizedSidebarViewModel: MemoizedViewModalFactory<
  Optional<SidebarViewModel>
> = {
  dependencies: {
    settings: ['bookingDetailsSectionHeader', 'calendarLayout'],
    subDependencies: [
      memoizedBookingDetailsViewModel.dependencies,
      memoizedLoginViewModel.dependencies,
      memoizedSummaryViewModel.dependencies,
      memoizedCtaViewModel.dependencies,
    ],
  },
  createViewModel: createSidebarViewModel,
};

export function createSidebarViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<SidebarViewModel> {
  const { getContent, settings, settingsParams, calendarSelections } = context;
  const { selectableSlotsAtSelectedTime } = state;

  const isTimetableWidget =
    isDailyAgendaWeeklyPickerLayout(settings, settingsParams) ||
    isWeeklyTimetableLayout(settings, settingsParams);
  if (isTimetableWidget) {
    return;
  }

  return {
    title: getContent({
      settingsParam: settingsParams.bookingDetailsSectionHeader,
      translationKey: calendarSelections
        ? 'app.settings.defaults.widget.appointment-details-header'
        : 'app.settings.defaults.widget.booking-details-header',
    }),
    bookingDetailsViewModel: !calendarSelections
      ? createBookingDetailsViewModel({ state, context })
      : undefined,
    summaryViewModel: calendarSelections
      ? createSummaryViewModel({ state, context })
      : undefined,
    loginViewModel: createLoginViewModel({ state, context }),
    ctaViewModel: createCtaViewModel({
      state,
      context,
      slots: selectableSlotsAtSelectedTime || [],
    }),
  };
}

export const createDummySidebarViewModel = ({
  state,
  context,
}: {
  state: CalendarState;
  context: CalendarContext;
}): SidebarViewModel => {
  const { getContent, settingsParams } = context;

  return {
    title: getContent({
      settingsParam: settingsParams.bookingDetailsSectionHeader,
      translationKey: 'app.settings.defaults.widget.booking-details-header',
    }),
    loginViewModel: createLoginViewModel({ state, context }),
    bookingDetailsViewModel: createDummyBookingDetailsViewModel({
      state,
      context,
    }),
    ctaViewModel: createDummyCtaViewModel({ state, context }),
  };
};
