import { Service, ServiceType, TimezoneType } from '@wix/bookings-uou-types';
import { Service as ServiceV2 } from '@wix/ambassador-bookings-services-v2-service/types';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  getEndOfMonthAsLocalDateTime,
  getLocalTimezone,
  getStartOfMonthAsLocalDateTime,
  getTodayLocalDateTimeStartOfDay,
} from '../../../../utils/dateAndTime/dateAndTime';
import { CalendarStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import { CalendarState } from '../../controller';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';
import { TriggeredByOptions } from '../../../../types/types';
import { SetSelectedRange } from '../setSelectedRange/setSelectedRange';
import { getLocalDateTimeRangeForDay } from '../../../../utils/getLocalDateTimeRangeForDay/getLocalDateTimeRangeForDay';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { AddError } from '../addError/addError';
import { InitializeCalendarDateOptions } from '../../../../utils/bi/consts';
import { bookingsCalendarPageLoaded } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  isDailyAgendaWeeklyPickerLayout,
  isDailyTimeSlotsWeeklyPickerLayout,
  isWeeklyTimeSlotsLayout,
  isWeeklyTimetableLayout,
} from '../../../../utils/layouts';
import { AutoSelectTime } from '../autoSelectTime/autoSelectTime';
import {
  getUrlQueryParamValue,
  BookingsQueryParams as BookingsQueryParamsUtils,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { isCalendarPage, isCalendarWidget } from '../../../../utils/presets';

export type InitializeWidget = () => Promise<void>;

export function createInitializeWidgetAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedDate: SetSelectedDate,
  setSelectedMonth: SetSelectedMonth,
  setSelectedRange: SetSelectedRange,
  addError: AddError,
  autoSelectTime: AutoSelectTime,
): InitializeWidget {
  return async () => {
    const {
      flowAPI,
      biLogger,
      settings,
      settingsParams,
      businessInfo,
      experiments,
      wixSdkAdapter,
      calendarApi,
      calendarSelections,
      preset,
    } = context;

    if (
      experiments.enabled('specs.bookings.useFlowApiEnvironmentOverSDK')
        ? flowAPI.environment.isSSR
        : wixSdkAdapter.isSSR()
    ) {
      return;
    }

    const [, setState] = getControllerState();

    const selectedTimezone = getSelectedTimezone(context);
    setState({ selectedTimezone });

    const [state] = getControllerState();
    const { servicesInView } = state;

    const initialLocalDate = await getInitializeCalendarDate({
      state,
      context,
      addError,
    });

    const isCalendar = isCalendarPage(preset) || isCalendarWidget(preset);

    if (
      experiments.enabled('specs.bookings.calendarAutoAssignResource') &&
      isCalendar &&
      !calendarSelections &&
      servicesInView.every(
        (service) => service.info.type === ServiceType.INDIVIDUAL,
      )
    ) {
      const serviceIds = servicesInView.map((service) => service.id);

      calendarApi
        .queryServicesV2({ serviceIds })
        .then((response) => {
          const servicesWithEnrichedPolicy = enrichServicePolicy(
            servicesInView,
            response.services,
          );

          setState({ servicesInView: servicesWithEnrichedPolicy });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (
      isWeeklyTimeSlotsLayout(settings, settingsParams) ||
      isWeeklyTimetableLayout(settings, settingsParams)
    ) {
      const range = getLocalDateTimeRangeForDay(
        businessInfo!.dateRegionalSettingsLocale!,
        initialLocalDate,
      );
      await setSelectedRange(range, TriggeredByOptions.INITIALIZE_WIDGET);
    } else if (
      isDailyTimeSlotsWeeklyPickerLayout(settings, settingsParams) ||
      isDailyAgendaWeeklyPickerLayout(settings, settingsParams)
    ) {
      const range = getLocalDateTimeRangeForDay(
        businessInfo!.dateRegionalSettingsLocale!,
        initialLocalDate,
      );
      setSelectedRange(range, TriggeredByOptions.INITIALIZE_WIDGET);
      await setSelectedDate(
        initialLocalDate,
        TriggeredByOptions.INITIALIZE_WIDGET,
      );
    } else {
      const startOfMonthAsLocalDateTime =
        getStartOfMonthAsLocalDateTime(initialLocalDate);
      setSelectedMonth(
        startOfMonthAsLocalDateTime,
        TriggeredByOptions.INITIALIZE_WIDGET,
      );
      await setSelectedDate(
        initialLocalDate,
        TriggeredByOptions.INITIALIZE_WIDGET,
      );
    }

    autoSelectTime(TriggeredByOptions.INITIALIZE_WIDGET);

    setState({ calendarStatus: CalendarStatus.IDLE });

    biLogger.report(bookingsCalendarPageLoaded({}));
  };
}

function getSelectedTimezone({
  businessInfo,
  wixSdkAdapter,
  flowAPI,
}: CalendarContext) {
  const localTimezone = getLocalTimezone();
  const preSelectedTimezone = flowAPI.experiments.enabled(
    'specs.bookings.isUseUtilsInsteadOfWixSDKEnabled',
  )
    ? getUrlQueryParamValue(
        flowAPI.controllerConfig.wixCodeApi,
        BookingsQueryParamsUtils.TIMEZONE,
      )
    : wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.TIMEZONE);

  const isPreselectedTimezoneValid = [
    businessInfo!.timeZone,
    localTimezone,
  ].includes(preSelectedTimezone);

  const defaultTimezone =
    businessInfo!.timezoneProperties?.defaultTimezone === TimezoneType.CLIENT
      ? localTimezone
      : businessInfo!.timeZone!;

  return isPreselectedTimezoneValid ? preSelectedTimezone : defaultTimezone;
}

const getInitializeCalendarDate = async ({
  state,
  context,
  addError,
}: {
  state: CalendarState;
  context: CalendarContext;
  addError: AddError;
}): Promise<string> => {
  const { selectedDate, selectedTimezone } = state;
  const { wixSdkAdapter, settings, settingsParams, calendarApi } = context;
  const isAnonymousCancellationFlow =
    (context.flowAPI.experiments.enabled(
      'specs.bookings.isUseUtilsInsteadOfWixSDKEnabled',
    )
      ? getUrlQueryParamValue(
          context.flowAPI.controllerConfig.wixCodeApi,
          BookingsQueryParamsUtils.REFERRAL,
        )
      : wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.REFERRAL)) ===
    'batel';

  const todayLocalDateTime = getTodayLocalDateTimeStartOfDay(selectedTimezone!);

  if (isAnonymousCancellationFlow) {
    return selectedDate || todayLocalDateTime;
  }
  if (selectedDate) {
    return selectedDate;
  }

  const shouldInitializeCalendarOnToday =
    settings.get(settingsParams.initializeCalendarDate) ===
    InitializeCalendarDateOptions.TODAY;
  if (shouldInitializeCalendarOnToday) {
    return todayLocalDateTime;
  } else {
    const sixMonthsFromNow = getEndOfMonthAsLocalDateTime(
      todayLocalDateTime,
      7,
    );
    const nextAvailableDate = await calendarApi.getNextAvailableDate(
      {
        fromAsLocalDateTime: todayLocalDateTime,
        toAsLocalDateTime: sixMonthsFromNow,
      },
      { state, context, onError: addError },
    );
    return nextAvailableDate || todayLocalDateTime;
  }
};

const enrichServicePolicy = (services: Service[], servicesV2?: ServiceV2[]) => {
  const idServicesV2Map =
    servicesV2?.reduce<Record<string, ServiceV2>>(
      (acc, service) => ({
        ...acc,
        [service.id!]: service,
      }),
      {},
    ) || {};

  const servicesWithEnrichedPolicy: Service[] = services.map((service) => {
    const serviceV2ResourcesPolicy =
      idServicesV2Map[service.id]?.bookingPolicy?.resourcesPolicy;

    if (!serviceV2ResourcesPolicy) {
      return service;
    }

    return {
      ...service,
      policy: {
        ...service.policy,
        isAutoAssignResourceAllowed:
          serviceV2ResourcesPolicy.enabled &&
          serviceV2ResourcesPolicy.autoAssignAllowed,
      },
    };
  });

  return servicesWithEnrichedPolicy;
};
